import "../styles/common.scss";

import { StencilProvider } from "@amzn/stencil-react-components/context";
import { Text } from "@amzn/stencil-react-components/text";
import createCache, { EmotionCache } from "@emotion/cache";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";

import { EmbeddedMetric, Logger } from "@/apis/Logger";
import { SnowplowTracker } from "@/helpers/snowplow";
import { appOnCloseFunction } from "@/helpers/WindowEventListenerMethods";

import messages from "../i18n";
import { LOCALES } from "../i18n/locales";
import { ErrorBoundary } from "./ErrorBoundary";
import Home from "./Home";

type AppProps = {
  currentLocale: string;
};

const cache: EmotionCache = createCache({
  key: "nheemotioncache",
  // TODO: Generate an unique nonce every time when the page loads or use other solutions besides using nonce
  // https://sim.amazon.com/issues/NHE-928
  nonce: "NjIxOWQ1MGMtNTcxNS00YTMzLThkOTUtNTUzMjU1MjZmZjY4",
});

export default function App(props: AppProps): JSX.Element {
  const currentLocale = props.currentLocale;
  // Default to use en-US if we can't find any matching locale code
  const localizedMessages =
    messages[currentLocale] ?? messages[LOCALES.ENGLISH];

  useEffect(() => {
    try {
      window.electronAPI.getDeviceInfo((event, deviceInfo) => {
        localStorage.setItem("deviceInfo", JSON.stringify(deviceInfo));
      });
    } catch (error) {
      // Ignoring error since this API is only available from the EZO electron app.
    }
    Logger.publishInfoMetric(new EmbeddedMetric("AppOpened", "Count", 1));
    // Initialize an instance immediately on load.
    SnowplowTracker.instance;

    /*
     * Capture refresh or page close events before the page closes with the best effort. Note that
     * this event is not captured properly using just useEffect clean up.
     */
    window.addEventListener("beforeunload", appOnCloseFunction, { once: true });
  }, []);

  return (
    <IntlProvider
      messages={localizedMessages}
      locale={currentLocale}
      defaultLocale={LOCALES.ENGLISH}
      defaultRichTextElements={{
        bold: /* istanbul ignore next: We use a custom IntlProvider in the tests */ (
          chunk
        ) => (
          <Text fontWeight="medium" display="inline">
            {chunk}
          </Text>
        ),
        i: /* istanbul ignore next: We use a custom IntlProvider in the tests */ (
          chunk
        ) => (
          <Text fontWeight="light" display="inline">
            <i>{chunk}</i>
          </Text>
        ),
      }}
    >
      {/*
        React-Intl is broken if the StencilProvider is not wrapped by the IntlProvider.
        https://stencil.a2z.com/for-developers/onboarding/brazil#configuring-live-announcements
      */}
      <StencilProvider locale={currentLocale} emotionCache={cache}>
        <ErrorBoundary>
          <Home />
        </ErrorBoundary>
      </StencilProvider>
    </IntlProvider>
  );
}
